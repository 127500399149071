import React from "react";
import { graphql } from "gatsby";
import parse from "html-react-parser";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useForm, ValidationError } from "@formspree/react";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import Layout from "../components/layout";
import { GatsbyImage } from "gatsby-plugin-image";
import { Helmet } from "react-helmet";
import SecondaryHero from "../components/secondary-hero";

const ShopTemplate = ({ data, data: { shop, site, heroImgMain } }) => {
	const [state, handleSubmit] = useForm("xzboblvd");

	const siteUrl = site.siteMetadata.siteUrl;
	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "Shops",
				item: {
					url: `${siteUrl}/shops`,
					id: `${siteUrl}/shops`,
				},
			},
			{
				"@type": "ListItem",
				position: "3",
				name: `${shop.title}`,
				item: {
					url: `${siteUrl}/shops/${shop.slug}`,
					id: `${siteUrl}/shops/${shop.slug}`,
				},
			},
		],
	};
	return (
		<Layout>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<GatsbySeo
				title={`${shop.title} | Friends of Eastbourne Hospital`}
				description=""
				openGraph={{
					url: `${siteUrl}/shops/${shop.slug}`,
					title: `${shop.title} | Friends of Eastbourne Hospital`,
					description: "",
					type: "article",
					images: [
						{
							url: `${heroImgMain?.localFile.publicURL}`,
							width: `${heroImgMain?.localFile.childImageSharp.original.width}`,
							height: `${heroImgMain?.localFile.childImageSharp.original.height}`,
							alt: `${heroImgMain?.altText}`,
						},
					],
				}}
			/>
			<div className="pb-md-7 pb-5">
				<section>
					<SecondaryHero title={shop.title} />
				</section>
				<section>
					<Container className=" py-md-7 py-5">
						<Row className="align-items-center">{parse(shop.content)}</Row>
					</Container>
				</section>
				<section>
					<Container>
						<Row className="text-center">
							<Col className="pb-5 pb-lg-0" lg={4}>
								<h2 className="fs-1 text-primary pb-3">Location</h2>
								<p className="px-lg-6">{shop.shopFields.location}</p>
							</Col>
							<Col className="pb-5 pb-lg-0" lg={4}>
								<h2 className="fs-1 text-primary  pb-3">Opening times</h2>
								{parse(shop.shopFields.openingTimes)}
							</Col>
							<Col lg={4}>
								<h2 className="fs-1 text-primary  pb-3">Telephone</h2>
								<a href="tel:+441323647188" className="text-decoration-none">
									01323 647 188
								</a>
							</Col>
						</Row>
					</Container>
				</section>
				{shop.shopFields.extraImages === true && (
					<section className="pt-5">
						<Container>
							<Row className="g-4">
								{" "}
								<Col className="" xs={12} lg={6}>
									<GatsbyImage
										image={
											shop.shopFields.extraImage1.localFile.childImageSharp
												.gatsbyImageData
										}
										alt=""
										className="h-100"
									/>
								</Col>
								<Col xs={12} lg={6}>
									<GatsbyImage
										image={
											shop.shopFields.extraImage2.localFile.childImageSharp
												.gatsbyImageData
										}
										alt=""
										className="h-100"
									/>
								</Col>
							</Row>
						</Container>
					</section>
				)}
				{shop.shopFields.mainShopGallery === true && (
					<section>
						<Container className=" pt-4 ">
							<Row className="g-4 justify-content-center">
								<Col xs={12} md={6} lg={4}>
									<GatsbyImage
										image={data.Img1.localFile.childImageSharp.gatsbyImageData}
										alt=""
										className="h-100"
									/>
								</Col>
								<Col xs={12} md={6} lg={4}>
									<GatsbyImage
										image={data.Img2.localFile.childImageSharp.gatsbyImageData}
										alt=""
										className="h-100"
									/>
								</Col>
								<Col xs={12} md={6} lg={4}>
									<GatsbyImage
										image={data.Img3.localFile.childImageSharp.gatsbyImageData}
										alt=""
										className="h-100"
									/>
								</Col>
								<Col xs={12} md={6} lg={4}>
									<GatsbyImage
										image={data.Img4.localFile.childImageSharp.gatsbyImageData}
										alt=""
										className="h-100"
									/>
								</Col>

								<Col xs={12} md={6} lg={4}>
									<GatsbyImage
										image={data.Img5.localFile.childImageSharp.gatsbyImageData}
										alt=""
										className="h-100"
									/>
								</Col>
								<Col xs={12} md={6} lg={4}>
									<GatsbyImage
										image={data.Img6.localFile.childImageSharp.gatsbyImageData}
										alt=""
										className="h-100"
									/>
								</Col>
								<Col xs={12} md={6} lg={4}>
									<GatsbyImage
										image={data.Img7.localFile.childImageSharp.gatsbyImageData}
										alt=""
										className="h-100"
									/>
								</Col>
								<Col xs={12} md={6} lg={4}>
									<GatsbyImage
										image={data.Img8.localFile.childImageSharp.gatsbyImageData}
										alt=""
										className="h-100"
									/>
								</Col>
								<Col xs={12} md={12} lg={4}>
									<GatsbyImage
										image={data.Img9.localFile.childImageSharp.gatsbyImageData}
										alt=""
										className="h-100"
									/>
								</Col>
							</Row>
						</Container>
					</section>
				)}
			</div>
		</Layout>
	);
};

export default ShopTemplate;

export const pageQuery = graphql`
	query ShopById($id: String!) {
		shop: wpShop(id: { eq: $id }) {
			id
			title
			content
			slug
			shopFields {
				mainShopGallery
				location
				extraImages
				openingTimes
				extraImage2 {
					altText
					localFile {
						publicURL
						childImageSharp {
							original {
								height
								width
							}
							gatsbyImageData(
								formats: [AUTO, WEBP]
								quality: 50
								transformOptions: { cropFocus: CENTER, fit: CONTAIN }
								layout: CONSTRAINED
								placeholder: BLURRED
							)
						}
					}
				}
				extraImage1 {
					altText
					localFile {
						publicURL
						childImageSharp {
							original {
								height
								width
							}
							gatsbyImageData(
								formats: [AUTO, WEBP]
								quality: 50
								transformOptions: { cropFocus: CENTER, fit: CONTAIN }
								layout: CONSTRAINED
								placeholder: BLURRED
							)
						}
					}
				}
			}
		}
		heroImgMain: wpMediaItem(title: { eq: "Home Hero Main" }) {
			altText
			localFile {
				publicURL
				childImageSharp {
					original {
						height
						width
					}
					gatsbyImageData(
						formats: [AUTO, WEBP]
						quality: 50
						transformOptions: { cropFocus: CENTER, fit: CONTAIN }
						layout: CONSTRAINED
						placeholder: BLURRED
					)
				}
			}
		}
		Img1: wpMediaItem(title: { eq: "MSG 1" }) {
			altText
			localFile {
				publicURL
				childImageSharp {
					gatsbyImageData(
						formats: [AUTO, WEBP]
						quality: 50
						transformOptions: { cropFocus: CENTER, fit: CONTAIN }
						layout: CONSTRAINED
						placeholder: BLURRED
					)
				}
			}
		}
		Img2: wpMediaItem(title: { eq: "MSG 2" }) {
			altText
			localFile {
				publicURL
				childImageSharp {
					gatsbyImageData(
						formats: [AUTO, WEBP]
						quality: 50
						transformOptions: { cropFocus: CENTER, fit: CONTAIN }
						layout: CONSTRAINED
						placeholder: BLURRED
					)
				}
			}
		}
		Img3: wpMediaItem(title: { eq: "MSG 3" }) {
			altText
			localFile {
				publicURL
				childImageSharp {
					gatsbyImageData(
						formats: [AUTO, WEBP]
						quality: 50
						transformOptions: { cropFocus: CENTER, fit: CONTAIN }
						layout: CONSTRAINED
						placeholder: BLURRED
					)
				}
			}
		}
		Img4: wpMediaItem(title: { eq: "MSG 4" }) {
			altText
			localFile {
				publicURL
				childImageSharp {
					gatsbyImageData(
						formats: [AUTO, WEBP]
						quality: 50
						transformOptions: { cropFocus: CENTER, fit: CONTAIN }
						layout: CONSTRAINED
						placeholder: BLURRED
					)
				}
			}
		}
		Img5: wpMediaItem(title: { eq: "MSG 5" }) {
			altText
			localFile {
				publicURL
				childImageSharp {
					gatsbyImageData(
						formats: [AUTO, WEBP]
						quality: 50
						transformOptions: { cropFocus: CENTER, fit: CONTAIN }
						layout: CONSTRAINED
						placeholder: BLURRED
					)
				}
			}
		}
		Img6: wpMediaItem(title: { eq: "MSG 6" }) {
			altText
			localFile {
				publicURL
				childImageSharp {
					gatsbyImageData(
						formats: [AUTO, WEBP]
						quality: 50
						transformOptions: { cropFocus: CENTER, fit: CONTAIN }
						layout: CONSTRAINED
						placeholder: BLURRED
					)
				}
			}
		}
		Img7: wpMediaItem(title: { eq: "MSG 7" }) {
			altText
			localFile {
				publicURL
				childImageSharp {
					gatsbyImageData(
						formats: [AUTO, WEBP]
						quality: 50
						transformOptions: { cropFocus: CENTER, fit: CONTAIN }
						layout: CONSTRAINED
						placeholder: BLURRED
					)
				}
			}
		}
		Img8: wpMediaItem(title: { eq: "MSG 8" }) {
			altText
			localFile {
				publicURL
				childImageSharp {
					gatsbyImageData(
						formats: [AUTO, WEBP]
						quality: 50
						transformOptions: { cropFocus: CENTER, fit: CONTAIN }
						layout: CONSTRAINED
						placeholder: BLURRED
					)
				}
			}
		}
		Img9: wpMediaItem(title: { eq: "MSG 9" }) {
			altText
			localFile {
				publicURL
				childImageSharp {
					gatsbyImageData(
						formats: [AUTO, WEBP]
						quality: 50
						transformOptions: { cropFocus: CENTER, fit: CONTAIN }
						layout: CONSTRAINED
						placeholder: BLURRED
					)
				}
			}
		}
		site {
			siteMetadata {
				siteUrl
			}
		}
	}
`;
